import logo from './TT.png';
import './App.css';
import './header.css';
import './section1.css';
import './PictureBody.css';
import './framecss.css'
import nail1 from './Nail1.jpeg';
import nail3 from './Nail3.jpeg';
import image1 from './image1.jpeg';
import image2 from './image2.jpeg';
import image3 from './image3.jpeg';
import image4 from './image4.jpeg';
import image5 from './image5.jpeg';
import image6 from './image6.jpeg';
import dip from './dip.webp';
import wax from './wax.webp';
import acrylic from './acrylic.webp'
import manicure from './manicure.jpeg';
import pedicure from './pedicure.jpeg';
import kids from './kids.webp';
import french from './french.webp';
import './responsive.css';
import addition from './addition.jpeg'
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
import { SocialIcon } from 'react-social-icons';
// ..
AOS.init();
var menuA = [acrylic,french,dip,manicure,pedicure,wax,kids,addition]
var services={
  'Acrylic Nail Service':{
    'FullSet ( Regular )' : '$45',
    'Fill-in': '$30',
    'FullSet ( Shellac )' : '$50',
    'Fill-in ( Shellac )' : '$40',
    'Full Set White Tip' : '$45',
    "Long Nail Different Price" : "Vary"

  },
  'Pink And White':{
    'Fullset' : "$60",
    "Fill" : "$35"
  },
  'Dipping Powder':{
    'Fullset' : '$45',
    "Pink&White" :'$50',
    "Add Fake Tip":"$50"
  },
  'Manicure': {
    "Regular Manicure" : "$25",
    "Shellac Manicure" : "$35"
  },
  'Pedicure': {
    "Regular Pedicure": "$35",
    "Deluxe Pedicure":"$45",
    "Super Deluxe":"$55",
    "Volcano Pedicure":"$65",
    "Shellac add on pedicure":"$15" 
  },
  'Waxing':{
    "Eyebrows":"$15",
    "Lip":"$10",
    "Chin":"$10",
    "Side Burn":"$15",
    "Full Face":"$40 & up"

  },
  'Kids Service':{
    "Nail Polish":"$10",
    "Toes Polish":"$10",
    "Manicure":"$20",
    "Pedicure":"$25"
  },
  'Addtional Service':{
    "Take off with service":"$5",
    "Take off only":"$10",
    "Repair":"$5 & up",
    "Design":"$5 & up",
    "Regular hands polish":"$15",
    "Regular toes polish":"$15",
    "Shellac toe polish":"$25",
    "Special shape":"$5 & up",
    "French/American tip":"$5 & up"
  }
}

var array=[image1,image2,image3,image4,image5,image6]
var review=[
  "My nails are always awesome and no matter what I bring them for inspiration, they pull it off. I work with my hands and they last and don’t break or chip. I won’t go anywhere else.",
  "Amazing. The super gentle nail tech was able to replicate an image while modifying the design to fit my needs. Fair prices paired with a clean environment means I will be there again in two weeks. Highly recommended.",
  "I loved my experience!! It was my first time there and they were very professional!! I was in and out within an hour. I got a full set and Justin was GREAT!! He knew exactly what to do without too many questions. I'll definitely be going back!! 5 STARS",
  "Excellent customer service. Amy re did my nails after they were poorly done and popping off. She did an amazing job and I'm beyond happy now 💗 💜 ❤️",
  "best nail place in the little rock/north little rock area! friendly, clean, and they always do an amazing job! the nails are well done, they last, and they’re so cute! go get that cute set you saw on instagram because they can do it for you!!"
]
function PictureBody(props){
  return(
    <div id ="PictureBody" >
      <p>Our Work </p>
      <div  id="instaContainer" class="grid-container">
        {array.map((a,b)=>{
          return(
            <div data-aos-duration="10000" data-aos={b % 2 == 0 ? "slide-left" : "slide-right"}  className={"item" + b + " grid-item"} >
                <img className="nailpics" style={b % 2 == 0 ? {border:"3px solid gold"} : {border:"3px solid black"}} src={a} ></img>
            </div>
          )
        })}
        
      </div>
    </div>
  )
}


function Followformore(props){
  return(
    <div id="Ending">
      <p id="endingre">Customer's Reviews</p>
      <div id="CustomerReview">
        {review.map((a,b)=>{
          return(
            <div data-aos={b % 2 == 0 ? "slide-left" : "slide-right"}  data-aos-duration="10000" class="reviewBox">
              <p class={"reviews"}>"{a}" -- Customer</p>
            </div>
          )
        })}
      </div>
      <div id ="followus">
        <div id ="socialGroup">
          <p id="followme">Follow Us For More</p>
          <div id ="icons">
            <SocialIcon class="socialIcon" url="https://www.instagram.com/nailsbylucus/" />
            <SocialIcon class="socialIcon" url="https://m.facebook.com/profile.php/?id=100069962664329" />
          </div>
          <div id ="Contactinfor">
            <a href="https://www.google.com/maps/place/3321+John+F+Kennedy+Blvd+a,+North+Little+Rock,+AR+72116/@34.7856818,-92.2609603,17z/data=!3m1!4b1!4m5!3m4!1s0x87d2bc582e160d8b:0x4112a4ccc202fbd7!8m2!3d34.7856818!4d-92.2609603?entry=ttu">3321 John F Kennedy Blvd a, North Little Rock, AR 72116</a>
            
            <a href="tel:501-771-0500"> 501-771-0500</a>
          </div>
        </div>
        
        <div><iframe id ="maps" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3276.805088580342!2d-92.2609603!3d34.7856818!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x87d2bc582e160d8b%3A0x4112a4ccc202fbd7!2s3321%20John%20F%20Kennedy%20Blvd%20a%2C%20North%20Little%20Rock%2C%20AR%2072116!5e0!3m2!1sen!2sus!4v1697001620500!5m2!1sen!2sus"></iframe></div>
      </div>

    </div>
  )
}

function FrameService(props){  
  return(
    <div style={props.style} className="frameSer" data-aos={props.dataaos} data-aos-duration={props.dataaosduration}>
      <div class="picContainer">
      <img src={props.image}></img>
      </div>
      <div className="listServices">
        <p className="titleFrame">{props.title}</p>
          {props.allList.map((a,b)=>{
            return(
            <li class="list">{a} : {props.listOfPrice[a]}</li>
            )
          })}
      </div>
    </div>
  )
}

function Menu(props){
  var listOfService = Object.keys(services)
  
  return(
    <div id = "menu">
      
      {listOfService.map((a,b)=>{
        return(
          <FrameService style={b % 2 == 0 ? {border:"0.5px solid gold"} : {border:"0.5px solid black"}} dataaos={b % 2 == 0 ? "slide-left" : "slide-right"}  dataaosduration="10000"image={menuA[b]} title={a} listOfPrice={services[a]} allList={Object.keys(services[a])}/>
        )})
      }
      </div>
  )
}


function App() {
  function handleReview(e) {
    e.preventDefault();
    var el = document.getElementById('endingre');
    el.scrollIntoView(true);
  }
  function handleMore(e) {
    e.preventDefault();
    var el = document.getElementById('followus');
    el.scrollIntoView(true);
  }
  function handleService(e) {
    e.preventDefault();
    var el = document.getElementById('menu');
    el.scrollIntoView(true);
  }

  return (
    <div className="App">
      <div id ="header">
          <img id="logo" src={logo}></img>
      </div>
      <div id ="section1">
        <div id ="imagesContainer">
          <img class = "nail1 commonnail" src={nail1}></img>
          <img class = "nail3 commonnail" src={nail3}></img>
        </div>
        
        <img class = "nail4 commonnail" src={nail3}></img>
        <div id ="alltextandbutton">
        <p class="title commontext">Welcome to TT Nails Salon</p>
        <p class="mess commontext">-- Best Service & Best Experience --</p>
        <div>
          
        <button onClick={handleService} class="unique-button"> All Service </button>
        <button onClick={handleMore} class="unique-button"> For More</button>
        <button onClick={handleReview} class="unique-button"> Reviews</button>
        </div>
        </div>
      </div>
      <div>
      <PictureBody/>
      </div>
      <div>
      <Followformore/>
      </div>
      <div id ="menuService">
      <p id="endingre">All Services</p>
        <Menu/>
      </div>
      
    </div>
  );
}


export default App;


